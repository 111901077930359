//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Header, Icon, Label }  from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Abstract extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section1",
        titleAs: "h2",
        title: "能動的音楽鑑賞サービス Songle とは",
        content: (
          <p>
            音楽をより深く理解しながら、能動的に音楽を鑑賞することができるウェブサービスです。音楽理解技術と呼ばれる音楽を理解する技術が備わっており、ビートの位置、コード進行、Ａメロ・Ｂメロ・サビといった繰り返しフレーズやボーカルの音高など、音楽を構成する主な要素を自動的に解析して表示することができます。
          </p>
        )
      },
      {
        type: "text",
        content: (
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                color="pink"
                as="a"
                href="//songle.jp"
                target="_">
                <Icon name="linkify" />
                能動的音楽鑑賞サービス Songle を使ってみる
              </Button>
            </Grid.Column>
          </Grid>
        )
      },
      {
        type: "text",
        titleAs: "h2",
        title: "Songle API",
        content: (
          <p>
            Songle API では Songle の機能や解析結果を外部から利用するための API やライブラリを開発者向けに提供しています。ここで提供されている API を組み合わせることで、音楽に関連したアプリケーションやウェブ上の好きな音楽の再生に合わせて光ったり動いたりする一体感のあるアプリケーションを手軽に開発することができます。
          </p>
        )
      },
      {
        type: "text",
        content: (
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                color="pink"
                as="a"
                href="/quickstart/javascript">
                <Icon name="linkify" />
                クイックスタートを確認する
              </Button>
            </Grid.Column>
          </Grid>
        )
      },
      {
        type: "text",
        content: (
          <Grid
            columns="equal"
            divided
            stackable>
            <Grid.Column>
              <Grid columns={1}>
                <Grid.Column>
                  <Label
                    color="pink"
                    ribbon
                    size="large"
                    as="a"
                    href="/rest">
                    Songle Rest API
                  </Label>
                </Grid.Column>
                <Grid.Column style={{ minHeight: "125px" }}>
                  音楽理解技術により解析されたビートの位置、コード進行、Ａメロ・Ｂメロ・サビといった繰り返しフレーズなどを HTTP から呼び出すことができます。
                </Grid.Column>
                <Grid.Column>
                  <Label>
                    現在の最新版 v1.0.0
                  </Label>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    color="pink"
                    as="a"
                    href="/rest">
                    <Icon name="linkify" />
                    より詳しいページへ
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns={1}>
                <Grid.Column>
                  <Label
                    color="pink"
                    ribbon
                    size="large"
                    as="a"
                    href="/sync">
                    Songle Sync API
                  </Label>
                </Grid.Column>
                <Grid.Column style={{ minHeight: "125px" }}>
                  ウェブ上の音楽の再生に合わせて多種多様な機器を同時制御することで、一体感のある演出ができる大規模音楽連動制御プラットフォームです。
                </Grid.Column>
                <Grid.Column>
                  <Label>
                    現在の最新版 v1.1.3
                  </Label>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    color="pink"
                    as="a"
                    href="/sync">
                    <Icon name="linkify" />
                    より詳しいページへ
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns={1}>
                <Grid.Column>
                  <Label
                    color="pink"
                    ribbon
                    size="large"
                    as="a"
                    href="/widget">
                    Songle Widget API
                  </Label>
                </Grid.Column>
                <Grid.Column style={{ minHeight: "125px" }}>
                  Songle を手軽にウェブサイトで利用できる外部埋め込みプレーヤーです。プレイヤーを制御して音楽に連動したアプリケーションを開発することもできます。
                </Grid.Column>
                <Grid.Column>
                  <Label>
                    現在の最新版 v1.0.0
                  </Label>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    color="pink"
                    as="a"
                    href="/widget">
                    <Icon name="linkify" />
                    より詳しいページへ
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        )
      }
    ]
  }
}

const modules = Abstract;

export default modules;
