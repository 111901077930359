//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

/**
 * @class
 */
class StageHistoryGraph extends React.Component
{
  static defaultProps = {
    style: {
      height: "300px"
    }
  };

  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      data: []
    };

    this.updateTimeline();
  }

  /**
   * @function
   */
  updateTimeline = (params = {}) => {
    let { syntax } = params;

    syntax = syntax || "camelcase";

    new Promise((ready, error) => {
      const queries = [];

      queries.push("syntax" + "=" + syntax);

      const request =
        fetch(`/api/v2/stages/${this.props.stage.attributes.accessToken}/histories.json?${queries.join("&")}`);

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            const data =
              response.data.map((datum) => {
                return {
                  date: new Date(datum.attributes.datetime).toLocaleDateString(),
                  time: new Date(datum.attributes.datetime).toLocaleTimeString(),
                  nodeCount: datum.attributes.nodeCount
                };
              });

            this.setState({
              data: data
            });

            ready(response);
          }
        });
    });
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { style } = this.props;

    return (
      <div style={style}>
        <ResponsiveContainer>
          <BarChart data={this.state.data}>
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="nodeCount"
              fill="#e03997" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const modules = StageHistoryGraph;

export default modules;
