//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Link } from "react-router-dom";

import { Dropdown, Icon, Menu, Responsive } from "semantic-ui-react";

/**
 * @const
 */
const AppMenu = (props) => {
  const { locale, signedInUser } = props;

  return (
    <Menu style={{ position: "absolute", borderBottom: "4px solid #a8a8a8", borderRadius: "0px", top: "0px", width: "100%" }}>
      <Menu.Item
        style={{ color: "#e17", fontSize: "16px", fontWeight: 100 }}
        as={Link}
        to="/">
        Songle API
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/">
        <Responsive
          {...Responsive.onlyMobile}
          as="span">
          <Icon name="home" />
        </Responsive>
        <Responsive
          {...Responsive.onlyTablet}
          as="span">
          <Icon name="home" />
        </Responsive>
        <Responsive
          {...Responsive.onlyComputer}
          as="span">
          <Icon name="home" />
          {
            locale == "en" ?
              "Home"
            :
              "ホーム"
          }
        </Responsive>
      </Menu.Item>
      {
        (() => {
          if(!signedInUser) {
            return;
          }

          return (
            <Menu.Item
              key="apps"
              as={Link}
              to="/u/apps">
              <Responsive
                {...Responsive.onlyMobile}
                as="span">
                <Icon name="th" />
              </Responsive>
              <Responsive
                {...Responsive.onlyTablet}
                as="span">
                <Icon name="th" />
              </Responsive>
              <Responsive
                {...Responsive.onlyComputer}
                as="span">
                <Icon name="th" />
                {
                  locale == "en" ?
                    "Apps"
                  :
                    "アプリ"
                }
              </Responsive>
            </Menu.Item>
          )
        })()
      }
      <Menu.Menu position="right">
        {<Menu.Item>
          <Responsive
            {...Responsive.onlyMobile}
            as={Dropdown}
            icon="world">
            <Dropdown.Menu>
              <Dropdown.Item
                as="a"
                href="/language/en">
                English
              </Dropdown.Item>
              <Dropdown.Item
                as="a"
                href="/language/ja">
                日本語
              </Dropdown.Item>
            </Dropdown.Menu>
          </Responsive>
          <Responsive
            {...Responsive.onlyTablet}
            as={Dropdown}
            icon="world">
            <Dropdown.Menu>
              <Dropdown.Item
                as="a"
                href="/language/en">
                English
              </Dropdown.Item>
              <Dropdown.Item
                as="a"
                href="/language/ja">
                日本語
              </Dropdown.Item>
            </Dropdown.Menu>
          </Responsive>
          <Responsive {...Responsive.onlyComputer}>
            <Icon name="world" />
            <Dropdown text={ locale == "en" ? "English (英語)" : "日本語 (Japanese)" }>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  href="/language/en">
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href="/language/ja">
                  日本語
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Responsive>
        </Menu.Item>}
        {
          (() => {
            const menuItems = [];

            if(signedInUser)
            {
              menuItems.push(
                <Menu.Item
                  key="user"
                  as={Link}
                  to="/u">
                  <Responsive
                    {...Responsive.onlyMobile}
                    as="span">
                    <Icon name="user" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyTablet}
                    as="span">
                    <Icon name="user" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyComputer}
                    as="span">
                    <Icon name="user" />
                    {signedInUser.attributes.email || signedInUser.attributes.name}
                  </Responsive>
                </Menu.Item>
              );

              menuItems.push(
                <Menu.Item
                  key="sign-out"
                  as="a"
                  href="/u/sign_out">
                  <Responsive
                    {...Responsive.onlyMobile}
                    as="span">
                    <Icon name="sign-out" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyTablet}
                    as="span">
                    <Icon name="sign-out" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyComputer}
                    as="span">
                    <Icon name="sign-out" />
                    {
                      locale === "en" ?
                        "Sign out"
                      :
                        "ログアウト"
                    }
                  </Responsive>
                </Menu.Item>
              );
            }
            else
            {
              menuItems.push(
                <Menu.Item
                  key="sign-in"
                  as={Link}
                  to="/u/sign_in">
                  <Responsive
                    {...Responsive.onlyMobile}
                    as="span">
                    <Icon name="sign-in" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyTablet}
                    as="span">
                    <Icon name="sign-in" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyComputer}
                    as="span">
                    <Icon name="sign-in" />
                    {
                      locale === "en" ?
                        "Sign in"
                      :
                        "ログイン"
                    }
                  </Responsive>
                </Menu.Item>
              );

              /*
              menuItems.push(
                <Menu.Item
                  key="sign-up"
                  as={Link}
                  to="/u/sign_up">
                  <Responsive
                    {...Responsive.onlyMobile}
                    as="span">
                    <Icon name="signup" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyTablet}
                    as="span">
                    <Icon name="signup" />
                  </Responsive>
                  <Responsive
                    {...Responsive.onlyComputer}
                    as="span">
                    <Icon name="signup" />
                    {
                      locale === "en" ?
                        "Sign up"
                      :
                        "新規登録"
                    }
                  </Responsive>
                </Menu.Item>
              );
              */
            }

            return menuItems;
          })()
        }
      </Menu.Menu>
    </Menu>
  );
}

const modules = AppMenu;

export default modules;
