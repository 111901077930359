//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Container, Divider, Grid, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import SignInForm from "../components/SignInPage/SignInForm";
import LegacySignInForm from "../components/SignInPage/LegacySignInForm";

/**
 * @class
 */
class SignInPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "sign-in",
    title: "ログイン",
    subtitle: "Songle アカウントでログイン",
    showMenu: false,
    showTocs: false,
    content: (
      <Segment className="square">
        <Container>
          <Grid style={{ margin: "24px 0px 36px 0px" }}>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SignInForm />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider
            content="OR"
            horizontal />
          <Grid centered>
            <Grid.Column
              mobile={16}
              computer={9}>
              <LegacySignInForm />
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    )
  };
}

const modules = SignInPage;

export default modules;
