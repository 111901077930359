//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import EventListener from "react-event-listener";

import { Grid, List, Ref, Responsive, Sticky } from "semantic-ui-react";

import AppHeader from "../components/AppHeader";
import AppSideMenu from "../components/AppSideMenu";
import AppFooter from "../components/AppFooter";

/**
 * @class
 */
class AppPageTemplate extends React.Component
{
  stickyRef = React.createRef();

  /**
   * @function
   */
  isMobile = () => {
    return window.innerWidth < 768;
  }

  /**
   * @function
   */
  isTablet = () => {
    return window.innerWidth < 992;
  }

  /**
   * @function
   */
  isComputer = () => {
    return window.innerWidth > 992;
  }

  /**
   * @function
   */
  onWindowResize = () => {
    this.forceUpdate((ev) => window.scrollTo({ top: 0 }));
  }

  /**
   * @function
   */
  renderMenu()
  {
    const { menu } = this.props;

    return menu || <AppSideMenu {...this.props} />;
  }

  /**
   * @function
   */
  renderTocs()
  {
    const { tocs } = this.props;

    return tocs;
  }

  /**
   * @function
   */
  renderContent()
  {
    const { content } = this.props;

    return content;
  }

  /**
   * @function
   * @override
   */
  render()
  {
    let { titleIcon, title, subtitle, showMenu, showTocs } = this.props;

    showMenu = showMenu != null ? showMenu : true;
    showTocs = showTocs != null ? showTocs : true;

    const doms1 = [];

    doms1.push(
      <AppHeader
        key={`${title}-header`}
        titleIcon={titleIcon}
        title={title}
        subtitle={subtitle} />
    );

    doms1.push(
      <Ref
        key={`${title}-body`}
        innerRef={this.stickyRef}>
        <Grid
          padded={this.isComputer() ? "horizontally" : true}
          reversed="computer">
          {
            (() => {
              const menuIsActive = !!showMenu;
              const tocsIsActive = !!showTocs;
              const sideMenuIsActive = menuIsActive || tocsIsActive;

              const doms2 = [];

              (() => {
                if(!sideMenuIsActive) {
                  return;
                }

                doms2.push(
                  <Grid.Column
                    key={`${title}-body-content1`}
                    mobile={16}
                    computer={!tocsIsActive ? 4 : 6}>
                    <Responsive
                      as={Sticky}
                      active={this.isComputer()}
                      context={this.stickyRef}>
                      <Grid
                        columns="equal"
                        padded={this.isComputer() ? "vertically" : false}
                        reversed="computer">
                        {
                          (() => {
                            if(!menuIsActive) {
                              return
                            }

                            return (
                              <Grid.Column
                                mobile={16}
                                computer={tocsIsActive ? 8 : 16}>
                                {this.renderMenu()}
                              </Grid.Column>
                            )
                          })()
                        }
                        {
                          (() => {
                            if(!tocsIsActive) {
                              return
                            }

                            return (
                              <Grid.Column
                                mobile={16}
                                computer={tocsIsActive ? 8 : 16}>
                                {this.renderTocs()}
                              </Grid.Column>
                            )
                          })()
                        }
                      </Grid>
                    </Responsive>
                  </Grid.Column>
                );
              })();

              (() => {
                doms2.push(
                  <Grid.Column
                      key={`${title}-body-content2`}
                      mobile={16}
                      computer={sideMenuIsActive ? tocsIsActive ? 10 : 12 : 16}>
                    <Responsive>
                      <Grid
                        columns="equal"
                        padded={this.isComputer() ? "vertically" : false}
                        reversed="computer">
                        <Grid.Column>
                          {this.renderContent()}
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                  </Grid.Column>
                );
              })();

              return doms2;
            })()
          }
        </Grid>
      </Ref>
    );

    doms1.push(
      <AppFooter
        key={`${title}-footer`} />
    );

    doms1.push(
      <EventListener
        key="event-listener"
        target="window"
        onResize={this.onWindowResize} />
    );

    return doms1;
  }
}

const modules = AppPageTemplate;

export default modules;
