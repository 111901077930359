//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Link } from "react-router-dom";

import { Divider, Icon, List, Segment } from "semantic-ui-react";

/**
 * @const
 */
const AppSideMenu = (props) => {
  const { locale } = props;

  return (
    <Segment className="square">
      <List size="small">
        <List.Item>
          <List.Content>
            <List.Header>
              <Icon name="info" />
              Overview
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.List>
              <List.Item
                as="a"
                href="/rest">
                Songle Rest API
              </List.Item>
              <List.Item
                as={Link}
                to="/sync">
                Songle Sync API
              </List.Item>
              <List.Item
                as="a"
                href="/widget">
                Songle Widget API
              </List.Item>
            </List.List>
          </List.Content>
        </List.Item>
        <List.Item>
          <Divider />
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>
              <Icon name="code" />
              Quickstart
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.List>
              {/*<List.Item
                as={Link}
                to="/quickstart/csharp">
                C# API
              </List.Item>*/}
              <List.Item
                as={Link}
                to="/quickstart/javascript">
                JavaScript API
              </List.Item>
              {/*<List.Item
                as={Link}
                to="/quickstart/ruby">
                Ruby API
              </List.Item>*/}
            </List.List>
          </List.Content>
        </List.Item>
        <List.Item>
          <Divider />
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>
              <Icon name="book" />
              References
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.List>
              {/*<List.Item
                as={Link}
                to="/references/csharp">
                C# API
              </List.Item>*/}
              <List.Item
                as={Link}
                to="/references/javascript">
                JavaScript API
              </List.Item>
              {/*<List.Item
                as={Link}
                to="/references/ruby">
                Ruby API
              </List.Item>*/}
            </List.List>
          </List.Content>
        </List.Item>
        <List.Item>
          <Divider />
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>
              <Icon name="file" />
              About Songle API
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.List>
              <List.Item
                as="a"
                href="/terms_of_use"
                target="_">
                {
                  locale == "en" ?
                    "Terms of Use"
                  :
                    "利用規約"
                }
              </List.Item>
              <List.Item
                as="a"
                href="/privacy_policy"
                target="_">
                {
                  locale == "en" ?
                    "Privacy policy"
                  :
                    "プライバシーポリシー"
                }
              </List.Item>
              <List.Item
                as="a"
                href="/provider"
                target="_">
                {
                  locale == "en" ?
                    "Procedures Based on Provider Liability Limitation Act"
                  :
                    "プロバイダ責任制限法上の手続き"
                }
              </List.Item>
            </List.List>
          </List.Content>
        </List.Item>
      </List>
    </Segment>
  );
}

const modules = AppSideMenu;

export default modules;
