//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Icon, Label, Segment } from "semantic-ui-react";

/**
 * @class
 */
class ArticleNote extends React.Component
{
  static defaultProps = {
    anchor: "",
    titleAs: "h4",
    titleIcon: null,
    title: null,
    subtitle: null,
    language: "javascript",
    content: "",
    style: { marginBottom: "36px" }
  };

  /**
   * @function
   * @override
   */
  render()
  {
    const { anchor, titleAs, titleIcon, title, subtitle, language, content, style } = this.props;

    return (
      <div
        id={anchor}
        style={style}>
        <Segment color="blue">
          <Label
            color="blue"
            ribbon>
            <Icon name="info" />
            ワンポイント
          </Label>
          {
            (() => {
              if(!title) {
                return;
              }

              return (
                <Header
                  as={titleAs}
                  style={{ margin: "12px 0" }}>
                  {
                    (() => {
                      if(titleIcon) {
                        return (
                          <Icon name={titleIcon} />
                        )
                      }
                    })()
                  }
                  <Header.Content>
                    {title}
                    <Header.Subheader content={subtitle} />
                  </Header.Content>
                </Header>
              )
            })()
          }
          {content}
        </Segment>
      </div>
    );
  }
}

const modules = ArticleNote;

export default modules;
