//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Link } from "react-router-dom";

import { Grid } from "semantic-ui-react";

/**
 * @const
 */
const AppFooter = () => {
  return (
    <div style={{ backgroundColor: "#e17", width: "100%" }}>
      <Grid
        columns="equal"
        padded>
        <Grid.Column
          style={{ color: "white" }}
          textAlign="right"
          as={Link}
          to="/">
          &copy; AIST Songle API project 2012-2019
        </Grid.Column>
      </Grid>
    </div>
  );
}

const modules = AppFooter;

export default modules;
