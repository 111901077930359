//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Header, Icon, Input, Segment, Table } from "semantic-ui-react";

import TimeAgo from "react-timeago";

import HistoryGraph from "./HistoryGraph";

import SensitiveInput from "../SensitiveInput";

/**
 * @class
 */
class Stage extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      stage: this.props.stage
    };
  }

  /**
   * @function
   */
  updateStage = (updateTarget) => {
    return new Promise((ready, error) => {
      const { app, onStageChange } = this.props;

      const request =
        fetch(`/api/v2/apps/${app.id}/stages/${this.state.stage.id}.json`, {
          method: "PUT",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            syntax: "camelcase",
            token: app.attributes.token,
            update_target: updateTarget
          })
        })

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            this.setState({
              stage: response.data
            });

            onStageChange &&
            onStageChange(this);

            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  deleteStage = () => {
    return new Promise((ready, error) => {
      const { app, onStageDelete } = this.props;

      const request =
        fetch(`/api/v2/apps/${app.id}/stages/${this.state.stage.id}.json`, {
          method: "DELETE",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            token: app.attributes.token
          })
        })

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            onStageDelete &&
            onStageDelete(this);

            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  handleAccessTokenChange = () => {
    if(confirm("この操作を実行してもよろしいですか？")) {
      this.updateStage("access_token");
    }
  }

  /**
   * @function
   */
  handleSecretTokenChange = () => {
    if(confirm("この操作を実行してもよろしいですか？")) {
      this.updateStage("secret_token");
    }
  }

  /**
   * @function
   */
  handleStageDelete = () => {
    if(confirm("この操作を実行してもよろしいですか？")) {
      this.deleteStage();
    }
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { stage } = this.state;

    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">
                ステージ {stage.attributes.accessToken}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                参加用トークン
              </Header>
              <SensitiveInput value={stage.attributes.accessToken} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="red"
                onClick={this.handleAccessTokenChange}>
                <Icon name="redo alternate" />
                参加用トークンを作り直す
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                認証用トークン
              </Header>
              <SensitiveInput value={stage.attributes.secretToken} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="red"
                onClick={this.handleSecretTokenChange}>
                <Icon name="redo alternate" />
                認証用トークンを作り直す
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                接続数
              </Header>
              <HistoryGraph
                stage={stage} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                ステージデータ
              </Header>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "30%" }}>
                      項目
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "70%" }}>
                      データ
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell content={<code>Stage ID</code>} />
                    <Table.Cell content={stage.id}/>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Active node count</code>} />
                    <Table.Cell content={stage.attributes.activeNodeCount} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Master node count</code>} />
                    <Table.Cell content={stage.attributes.masterNodeCount} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Media source URL</code>} />
                    <Table.Cell content={<a href={stage.attributes.mediaSourceUrl} target="_">{stage.attributes.mediaSourceUrl}</a>}/>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>State</code>} />
                    <Table.Cell content={stage.attributes.stateId == 1 ? "再生" : "停止"}/>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Song playing duration</code>} />
                    <Table.Cell content={`${stage.attributes.duration} ms`} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Song playing position</code>} />
                    <Table.Cell content={`${stage.attributes.position} ms`} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Message</code>} />
                    <Table.Cell content={stage.attributes.message}/>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Created at</code>} />
                    <Table.Cell content={<TimeAgo date={stage.attributes.createdAt} />} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content={<code>Updated at</code>} />
                    <Table.Cell content={<TimeAgo date={stage.attributes.updatedAt} />} />
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="red"
                onClick={this.handleStageDelete}>
                <Icon name="trash" />
                このステージを削除
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const modules = Stage;

export default modules;
