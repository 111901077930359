//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Container, Divider, Grid, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import SignUpForm from "../components/SignUpPage/SignUpForm";
import LegacySignUpForm from "../components/SignUpPage/LegacySignUpForm";

/**
 * @class
 */
class SignUpPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "signup",
    title: "新規登録",
    subtitle: "Songle アカウントで新規登録",
    showMenu: false,
    showTocs: false,
    content: (
      <Segment className="square">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SignUpForm />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  };
}

const modules = SignUpPage;

export default modules;
