//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Container, Grid, Header, Icon, Segment, Table } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

/**
 * @class
 */
class UserPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "user",
    title: "ユーザ情報",
    subtitle: "登録されているユーザ情報",
    showTocs: false
  };

  /**
   * @function
   */
  renderContent()
  {
    const { flash, signedInUser } = this.props;

    return (
      <Segment className="square">
        <Container>
          <Header as="h3">
            サービス連携
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {
                  !signedInUser.attributes.provider ?
                    <Button
                      color="pink"
                      as="a"
                      href="/auth/songle">
                      <Icon name="sign-in" />
                      Songleアカウントと連携する
                    </Button>
                  :
                    <Button
                      color="grey"
                      disabled
                      as="a"
                      href="/auth/songle">
                      <Icon name="sign-in" />
                      Songleアカウントと連携済み
                    </Button>
                }
                {
                  flash.error === "already" ?
                    <div style={{ color: "red" }}>
                      連携しようとしたアカウントは既に別のアカウントに紐づけられています。
                    </div>
                  :
                    null
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Header as="h3">
            その他
          </Header>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "25%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "75%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  メールアドレス
                </Table.Cell>
                <Table.Cell>
                  <strong>{signedInUser.attributes.email ? "登録済み" : "未登録"}</strong>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  認証パスワード
                </Table.Cell>
                <Table.Cell>
                  <strong>{signedInUser.attributes.email ? "登録済み" : "未登録"}</strong>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      </Segment>
    );
  }
}

const modules = UserPage;

export default modules;
