//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import Content from "./UserApp/Content";
import Explorer from "./UserApp/Explorer";
import Form from "./UserApp/Form";

const modules = {
  Content,
  Explorer,
  Form
};

export default modules;
