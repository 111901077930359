//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Icon } from "semantic-ui-react";

/**
 * @class
 */
class ArticleText extends React.Component
{
  static defaultProps = {
    anchor: "",
    titleAs: "h2",
    titleIcon: null,
    title: null,
    titleEn: null,
    titleJa: null,
    subtitle: null,
    language: "javascript",
    locale: "ja",
    content: "",
    contentEn: null,
    contentJa: null,
    style: { marginBottom: "36px" }
  };

  /**
   * @function
   * @override
   */
  render()
  {
    let { anchor, titleAs, titleIcon, title, titleEn, titleJa, subtitle, language, locale, content, contentEn, contentJa, style } = this.props;

    return (
      <div
        id={anchor}
        style={style}>
        {
          (() => {
            if(locale == "en" && titleEn == null ||
               locale == "ja" && titleJa == null) {
              return;
            }

            return (
              <Header as={titleAs}>
                {
                  (() => {
                    if(titleIcon) {
                      return (
                        <Icon name={titleIcon} />
                      )
                    }
                  })()
                }
                <Header.Content>
                  {
                    locale == "en" ?
                      titleEn || title
                    :
                      titleJa || title
                  }
                  <Header.Subheader content={subtitle} />
                </Header.Content>
              </Header>
            )
          })()
        }
        {
          locale == "en" ?
            contentEn || content
          :
            contentJa || content
        }
      </div>
    );
  }
}

const modules = ArticleText;

export default modules;
