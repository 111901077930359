//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import Content from "./Stage/Content";
import Explorer from "./Stage/Explorer";

const modules = {
  Content,
  Explorer
};

export default modules;
