//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Form, Header, Icon, Input, Label, Segment } from "semantic-ui-react";

/**
 * @const
 */
const LegacySignInForm = () => {
  const doms = [];

  doms.push(
    <Segment
      key="legacy-sign-in-form-message"
      color="blue">
      <Label
        color="blue"
        ribbon>
        <Icon name="info" />
        お知らせ
      </Label>
      <Header
        as="h4"
        style={{ margin: "12px 0" }}>
        以前の登録情報でログインについて
      </Header>
      <p>
        「以前の登録情報」とは、過去メールアドレスと認証パスワードを入力して登録したアカウントを指します。今後、この認証方式は提供を終了します（終了する時期については、登録されているメールアドレスにて後日お知らせします）。
        <br/>
        <br/>
        今後も「以前の登録情報」を使ったアカウントを利用する場合は、「以前の登録情報」を使ったログイン後の設定画面より「Songleアカウントと連携する」ボタンを押していただき、次回以降のログインには「Songleアカウントでログイン」よりログインするようにお願いします。
      </p>
    </Segment>
  );

  doms.push(
    <Form
      key="legacy-sign-in-form"
      action="/u/sign_in"
      method="post">
      <Form.Field>
        <Input
          name="authenticity_token"
          type="hidden"
          value={ReactOnRails.authenticityToken()} />
      </Form.Field>
      <Form.Field>
        <label>
          メールアドレス
        </label>
        <Input
          name="user[email]"
          type="text"
          fluid
          icon="mail"
          iconPosition="left" />
      </Form.Field>
      <Form.Field>
        <label>
          認証パスワード
        </label>
        <Input
          name="user[password]"
          type="password"
          fluid
          icon="lock"
          iconPosition="left" />
      </Form.Field>
      <Button type="submit">
        以前の登録情報でログイン
      </Button>
    </Form>
  );

  return doms;
}

const modules = LegacySignInForm;

export default modules;
