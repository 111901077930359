//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Icon } from "semantic-ui-react";

/**
 * @const
 */
const AppHeader = (props) => {
  const { titleIcon, title, subtitle } = props;

  return (
    <div style={{ padding: `${ !!subtitle ? 72 : 84 }px 24px 0px 24px`, backgroundColor: "#e17", width: "100%", minHeight: "150px" }}>
      <Header
        inverted
        as="h1">
        {
          (() => {
            if(titleIcon) {
              return (
                <Icon name={titleIcon} />
              )
            }
          })()
        }
        <Header.Content>
          {title}
          <Header.Subheader content={subtitle} />
        </Header.Content>
      </Header>
    </div>
  );
}

const modules = AppHeader;

export default modules;
