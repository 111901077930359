//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Icon, Input, Responsive } from "semantic-ui-react";

import { CopyToClipboard } from "react-copy-to-clipboard";

/**
 * @class
 */
class SensitiveInput extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      visible: false
    };
  }

  /**
   * @function
   */
  toggleVisible = () => {
    this.setState({
      visible: !this.state.visible
    });
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { value } = this.props;

    return (
      <Grid columns={3}>
        <Grid.Column width={10}>
          <Input
            type={this.state.visible ? "" : "password"}
            value={this.state.visible ? value : "This is sentive string"}
            fluid
            icon="lock"
            iconPosition="left" />
        </Grid.Column>
        <Grid.Column width={3}>
          <CopyToClipboard text={value}>
            <Responsive
              {...Responsive.onlyMobile}
              as={Button}
              color="pink"
              fluid>
              <Icon name="copy" />
            </Responsive>
          </CopyToClipboard>
          <CopyToClipboard text={value}>
            <Responsive
              {...Responsive.onlyTablet}
              as={Button}
              color="pink"
              fluid>
              <Icon name="copy" />
            </Responsive>
          </CopyToClipboard>
          <CopyToClipboard text={value}>
            <Responsive
              {...Responsive.onlyComputer}
              as={Button}
              color="pink"
              fluid>
              <Icon name="copy" />
              コピー
            </Responsive>
          </CopyToClipboard>
        </Grid.Column>
        <Grid.Column width={3}>
          <Responsive
            {...Responsive.onlyMobile}
            as={Button}
            color="pink"
            fluid
            onClick={this.toggleVisible}>
            <Icon name="eye" />
          </Responsive>
          <Responsive
            {...Responsive.onlyTablet}
            as={Button}
            color="pink"
            fluid
            onClick={this.toggleVisible}>
            <Icon name="eye" />
          </Responsive>
          <Responsive
            {...Responsive.onlyComputer}
            as={Button}
            color="pink"
            fluid
            onClick={this.toggleVisible}>
            <Icon name="eye" />
            表示
          </Responsive>
        </Grid.Column>
      </Grid>
    );
  }
}

const modules = SensitiveInput;

export default modules;
