//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Icon, Label, Segment } from "semantic-ui-react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs";

/**
 * @class
 */
class ArticleCode extends React.Component
{
  static defaultProps = {
    anchor: "",
    titleAs: "h2",
    titleIcon: null,
    title: null,
    subtitle: null,
    language: "javascript",
    content: "",
    style: { marginBottom: "36px" }
  };

  /**
   * @function
   * @override
   */
  render()
  {
    const { anchor, titleAs, titleIcon, title, subtitle, language, content, style } = this.props;

    return (
      <div
        id={anchor}
        style={style}>
        {
          (() => {
            if(!title) {
              return;
            }

            return (
              <Header as={titleAs}>
                {
                  (() => {
                    if(titleIcon) {
                      return (
                        <Icon name={titleIcon} />
                      )
                    }
                  })()
                }
                <Header.Content>
                  {title}
                  <Header.Subheader content={subtitle} />
                </Header.Content>
              </Header>
            )
          })()
        }
        <Segment inverted>
          <Label
            color="grey"
            ribbon>
            <Icon name="code" />
            サンプルコード
          </Label>
          <SyntaxHighlighter
            language={language}
            style={tomorrowNight}>
            {adjustTextIndent(content)}
          </SyntaxHighlighter>
        </Segment>
      </div>
    );
  }
}

/**
 * @function
 * @private
 */
function adjustTextIndent(
  text
)
{
  const adjustedTextLines = [];

  if(typeof text !== "string") {
    return text;
  }

  let indent = 0;

  text.split("\n").forEach((textLine, index) => {
    if(indent == 0) {
      const regexp = new RegExp("^\\s+", "g");

      regexp.test(textLine);

      indent = regexp.lastIndex;
    }

    textLine =
      textLine.replace(new RegExp("^\\s{0," + indent + "}", "g"), "");

    if(indent == 0) {
      const regexp = new RegExp("^\\t+", "g");

      regexp.test(textLine);

      indent = regexp.lastIndex;
    }

    textLine =
      textLine.replace(new RegExp("^\\t{0," + indent + "}", "g"), "");

    if(index != 0) {
      adjustedTextLines.push(textLine);
    }
  });

  return adjustedTextLines.join("\n");
}

const modules = ArticleCode;

export default modules;
