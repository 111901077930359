//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Icon, Table } from "semantic-ui-react";

/**
 * @class
 */
class ArticleTable extends React.Component
{
  static defaultProps = {
    anchor: "",
    titleAs: "h2",
    titleIcon: null,
    title: null,
    subtitle: null,
    language: "javascript",
    content: "",
    style: { marginBottom: "36px" }
  };

  /**
   * @function
   * @override
   */
  render()
  {
    const { anchor, titleAs, titleIcon, title, subtitle, language, content, style } = this.props;

    return (
      <div
        id={anchor}
        style={style}>
        {
          (() => {
            if(!title) {
              return;
            }

            return (
              <Header as={titleAs}>
                {
                  (() => {
                    if(titleIcon) {
                      return (
                        <Icon name={titleIcon} />
                      )
                    }
                  })()
                }
                <Header.Content>
                  {title}
                  <Header.Subheader>
                    {subtitle}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            )
          })()
        }
        <Table
          basic="very"
          celled>
          <Table.Header>
            <Table.Row>
              {
                (() => {
                  const { headers } = content || {};

                  if(!headers) {
                    return;
                  }

                  return headers.map((header, index) => {
                    const { style, content } = header;

                    return (
                      <Table.HeaderCell
                        key={index}
                        style={style}>
                        {content}
                      </Table.HeaderCell>
                    );
                  });
                })()
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              (() => {
                const { bodies } = content || {};

                if(!bodies) {
                  return;
                }

                return bodies.map((body, index) => {
                  const { cells } = body;

                  return (
                    <Table.Row key={index}>
                      {
                        cells.map((cell, index) => {
                          const { content } = cell;

                          return (
                            <Table.Cell key={index}>
                              {content}
                            </Table.Cell>
                          );
                        })
                      }
                    </Table.Row>
                  );
                });
              })()
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              {
                (() => {
                  const { footers } = content || {};

                  if(!footers) {
                    return;
                  }

                  return footers.map((footer, index) => {
                    const { style, content } = footer;

                    return (
                      <Table.Cell
                        key={index}
                        style={style}>
                        {content}
                      </Table.Cell>
                    );
                  });
                })()
              }
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

const modules = ArticleTable;

export default modules;
