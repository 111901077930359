//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Icon } from "semantic-ui-react";

/**
 * @const
 */
const SignUpForm = () => {
  return (
    <Button
      as="a"
      href="/auth/songle"
      color="pink"
      size="massive">
      <Icon name="signup" />
      Songle アカウントで新規登録
    </Button>
  );
}

const modules = SignUpForm;

export default modules;
