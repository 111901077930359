//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Image, Grid } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgBannerSongle from "../../../../../assets/banners/songle";
import ImgBannerSongrium from "../../../../../assets/banners/songrium";
import ImgBannerSongroid from "../../../../../assets/banners/songroid";
import ImgBannerTextalive from "../../../../../assets/banners/textalive";

/**
 * @class
 */
class RelatedWorks extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section2",
        titleAs: "h3",
        title: "産総研の関連サービス",
        content: (
          <Grid columns={1} textAlign="right">
            <Grid.Column>
              <Image
                src={ImgBannerSongle}
                as="a"
                href="//songle.jp"
                target="_" />
            </Grid.Column>
            <Grid.Column>
              <Image
                src={ImgBannerSongrium}
                as="a"
                href="//songrium.jp"
                target="_" />
            </Grid.Column>
            <Grid.Column>
              <Image
                src={ImgBannerTextalive}
                as="a"
                href="//textalive.jp"
                target="_" />
            </Grid.Column>
          </Grid>
        )
      }
    ]
  }
}

const modules = RelatedWorks;

export default modules;
