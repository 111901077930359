//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ArticleCode from "./ArticleCode";
import ArticleNote from "./ArticleNote";
import ArticleTable from "./ArticleTable";
import ArticleText from "./ArticleText";

/**
 * @class
 */
class ArticleTemplate extends React.Component
{
  static defaultProps = {
    articles: [],
    autoAnchor: false
  };

  /**
   * @function
   * @override
   */
  render()
  {
    const { articles, autoAnchor, locale } = this.props;

    return articles.map((article, index) => {
      let { type, anchor, titleAs, titleIcon, title, titleEn, titleJa, subtitle, language, content, contentEn, contentJa } = article;

      if(!!autoAnchor) {
        anchor = anchor || `section${index + 1}`;
      }

      switch(type)
      {
        case "code":
          return (
            <ArticleCode
              key={`${index}-${title}-${type}`}
              anchor={anchor}
              titleAs={titleAs}
              titleIcon={titleIcon}
              title={title}
              titleEn={titleEn}
              titleJa={titleJa}
              subtitle={subtitle}
              language={language}
              locale={locale}
              content={content}
              contentEn={contentEn}
              contentJa={contentJa} />
          );
        case "note":
          return (
            <ArticleNote
              key={`${index}-${title}-${type}`}
              anchor={anchor}
              titleAs={titleAs}
              titleIcon={titleIcon}
              title={title}
              titleEn={titleEn}
              titleJa={titleJa}
              subtitle={subtitle}
              language={language}
              locale={locale}
              content={content}
              contentEn={contentEn}
              contentJa={contentJa} />
          );
        case "table":
          return (
            <ArticleTable
              key={`${index}-${title}-${type}`}
              anchor={anchor}
              titleAs={titleAs}
              titleIcon={titleIcon}
              title={title}
              titleEn={titleEn}
              titleJa={titleJa}
              subtitle={subtitle}
              language={language}
              locale={locale}
              content={content}
              contentEn={contentEn}
              contentJa={contentJa} />
          );
        case "text":
          return (
            <ArticleText
              key={`${index}-${title}-${type}`}
              anchor={anchor}
              titleAs={titleAs}
              titleIcon={titleIcon}
              title={title}
              titleEn={titleEn}
              titleJa={titleJa}
              subtitle={subtitle}
              language={language}
              locale={locale}
              content={content}
              contentEn={contentEn}
              contentJa={contentJa} />
          );
      }
    });
  }
}

const modules = ArticleTemplate;

export default modules;
