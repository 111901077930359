//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Icon, Pagination } from "semantic-ui-react";

import Stage from "../Stage";

/**
 * @class
 */
class StageExplorer extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      totalPages: 1,
      activePage: 1,
      stages: []
    };

    this.updatePage();
  }

  /**
   * @function
   */
  createStage = () => {
    const { app } = this.props;

    return new Promise((ready, error) => {
      const request =
        fetch(`/api/v2/apps/${app.id}/stages.json`, {
          method: "POST",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            token: app.attributes.token
          })
        })

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  updatePage = (params = {}) => {
    let { page, per_page, syntax } = params;

    page     = page     || 1;
    per_page = per_page || 1;
    syntax   = syntax   || "camelcase";

    return new Promise((ready, error) => {
      const { app } = this.props;

      const queries = [];

      queries.push("page"     + "=" + page);
      queries.push("per_page" + "=" + per_page);
      queries.push("syntax"   + "=" + syntax);
      queries.push("token"    + "=" + app.attributes.token);

      const request =
        fetch(`/api/v2/apps/${app.id}/stages.json?${queries.join("&")}`);

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            this.setState({
              totalPages: response.meta.totalPages,
              activePage: response.meta.activePage,
              stages: response.data
            });

            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  handleStageCreate = () => {
    this.createStage()
      .then(() => this.updatePage());
  }

  /**
   * @function
   */
  handlePageChange = (ev, { activePage }) => {
    this.updatePage({
      page: activePage
    });
  }

  /**
   * @function
   */
  handleStageDelete = () => {
    this.updatePage();
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { totalPages, stages } = this.state;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button
              color="pink"
              onClick={this.handleStageCreate}>
              <Icon name="plus" />
              新しいステージを作る
            </Button>
          </Grid.Column>
        </Grid.Row>
        {
          (() => {
            if(totalPages < 2) {
              return;
            }

            return (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Pagination
                    size="mini"
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.handlePageChange} />
                </Grid.Column>
              </Grid.Row>
            );
          })()
        }
        <Grid.Row>
          <Grid.Column>
            {
              (() => {
                const { app } = this.props;

                return stages.map((stage) => {
                  return (
                    <Stage.Content
                      key={stage.attributes.accessToken}
                      app={app}
                      stage={stage}
                      onStageDelete={this.handleStageDelete} />
                  );
                });
              })()
            }
          </Grid.Column>
        </Grid.Row>
        {
          (() => {
            if(totalPages < 2) {
              return;
            }

            return (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Pagination
                    size="mini"
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.handlePageChange} />
                </Grid.Column>
              </Grid.Row>
            );
          })()
        }
      </Grid>
    )
  }
}

const modules = StageExplorer;

export default modules;
