//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import Abstract from "../components/TopPage/Abstract";
import RelatedWorks from "../components/TopPage/RelatedWorks";

/**
 * @class
 */
class TopPage extends AppPageTemplate
{
  static defaultProps = {
    title: "Songle API",
    subtitle: "音楽理解技術で、あたらしい楽しさをあなたのアプリでも！",
    tocs: (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                目次
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                <List.Item
                  content="概要"
                  as="a"
                  href="#section1" />
                <List.Item
                  content="産総研の関連サービス"
                  as="a"
                  href="#section2" />
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    ),
    content: (
      <Segment className="square">
        <Abstract />
        <RelatedWorks />
      </Segment>
    )
  };
}

const modules = TopPage;

export default modules;
